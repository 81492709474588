import React, { FC } from 'react';

import { PageContent } from '@stur/components/layout/page-content';
import { PageTitle } from '@stur/components/layout/page-title';
import styles from '@stur/styles/modules/general-page-content.module.scss';

export const PrivacyPolicyPage: FC = () => {
  return (
    <>
      <PageTitle header="Privacy Policy" />
      <PageContent className={styles.generalPageContent}>
        <div className="grid-container general-page-content">
          <p>Effective date: September 19, 2018</p>
          <p>
            Stur LLC (&#34;us&#34;, &#34;we&#34;, or &#34;our&#34;) operates the Stur mobile
            application (hereinafter referred to as the &#34;Service&#34;).
          </p>
          <p>
            This page informs you of our policies regarding the collection, use and disclosure of
            personal data when you use our Service and the choices you have associated with that
            data.
          </p>
          <p>
            We use your data to provide and improve the Service. By using the Service, you agree to
            the collection and use of information in accordance with this policy. Unless otherwise
            defined in this Privacy Policy, the terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions.
          </p>

          <h2>Definitions</h2>
          <dl>
            <dt>Service</dt>
            <dd>Service is the Stur mobile application operated by Stur LLC</dd>

            <dt>Personal Data</dt>
            <dd>
              Personal Data means data about a living individual who can be identified from those
              data (or from those and other information either in our possession or likely to come
              into our possession).
            </dd>
            <dt>Usage Data</dt>
            <dd>
              Usage Data is data collected automatically either generated by the use of the Service
              or from the Service infrastructure itself (for example, the duration of a page visit).
            </dd>
            <dt>Cookies</dt>
            <dd>Cookies are small files stored on your device (computer or mobile device).</dd>
          </dl>

          <h2>Information Collection and Use</h2>
          <p>
            We collect several different types of information for various purposes to provide and
            improve our Service to you.
          </p>
          <h3>Types of Data Collected</h3>
          <h4>Personal Data</h4>
          <p>
            While using our Service, we may ask you to provide us with certain personally
            identifiable information that can be used to contact or identify you (&#34;Personal
            Data&#34;). Personally identifiable information may include, but is not limited to:
          </p>
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Cookies and Usage Data</li>
          </ul>
          <h4>Usage Data</h4>
          <p>
            When you access the Service with a mobile device, we may collect certain information
            automatically, including, but not limited to, the type of mobile device you use, your
            mobile device unique ID, the IP address of your mobile device, your mobile operating
            system, the type of mobile Internet browser you use, unique device identifiers and other
            diagnostic data (&#34;Usage Data&#34;).
          </p>
          <h4>Tracking &amp; Cookies Data</h4>
          <p>
            We use cookies and similar tracking technologies to track the activity on our Service
            and we hold certain information. Cookies are files with a small amount of data which may
            include an anonymous unique identifier. Cookies are sent to your browser from a website
            and stored on your device. Other tracking technologies are also used such as beacons,
            tags and scripts to collect and track information and to improve and analyse our
            Service. You can instruct your browser to refuse all cookies or to indicate when a
            cookie is being sent. However, if you do not accept cookies, you may not be able to use
            some portions of our Service. Examples of Cookies we use:
          </p>
          <ul>
            <li>
              <strong>Session Cookies.</strong> We use Session Cookies to operate our Service.
            </li>
            <li>
              <strong>Preference Cookies.</strong> We use Preference Cookies to remember your
              preferences and various settings.
            </li>
            <li>
              <strong>Security Cookies.</strong> We use Security Cookies for security purposes.
            </li>
          </ul>

          <h2>Use of Data</h2>
          <p>Stur LLC uses the collected data for various purposes:</p>
          <ul>
            <li>To provide and maintain our Service</li>
            <li>To notify you about changes to our Service</li>
            <li>
              To allow you to participate in interactive features of our Service when you choose to
              do so
            </li>
            <li>To provide customer support</li>
            <li>To gather analysis or valuable information so that we can improve our Service</li>
            <li>To monitor the usage of our Service</li>
            <li>To detect, prevent and address technical issues</li>
          </ul>

          <h2>Transfer of Data</h2>
          <p>
            Your information, including Personal Data, may be transferred to - and maintained on -
            computers located outside of your state, province, country or other governmental
            jurisdiction where the data protection laws may differ from those of your jurisdiction.
          </p>
          <p>
            If you are located outside and choose to provide information to us, please note that we
            transfer the data, including Personal Data, to and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of such information
            represents your agreement to that transfer.
          </p>
          <p>
            Stur LLC will take all the steps reasonably necessary to ensure that your data is
            treated securely and in accordance with this Privacy Policy and no transfer of your
            Personal Data will take place to an organisation or a country unless there are adequate
            controls in place including the security of your data and other personal information.
          </p>

          <h2>Disclosure of Data</h2>
          <h3>Legal Requirements</h3>
          <p>
            Stur LLC may disclose your Personal Data in the good faith belief that such action is
            necessary to:
          </p>
          <ul>
            <li>To comply with a legal obligation</li>
            <li>To protect and defend the rights or property of Stur LLC</li>
            <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>To protect the personal safety of users of the Service or the public</li>
            <li>To protect against legal liability</li>
          </ul>

          <h2>Security of Data</h2>
          <p>
            The security of your data is important to us but remember that no method of transmission
            over the Internet or method of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Data, we cannot guarantee its
            absolute security.
          </p>

          <h2>Service Providers</h2>
          <p>
            We may employ third party companies and individuals to facilitate our Service
            (&#34;Service Providers&#34;), provide the Service on our behalf, perform
            Service-related services or assist us in analysing how our Service is used. These third
            parties have access to your Personal Data only to perform these tasks on our behalf and
            are obligated not to disclose or use it for any other purpose.
          </p>
          <h3>Analytics</h3>
          <p>
            We may use third-party Service Providers to monitor and analyse the use of our Service.
          </p>
          <h4>Google Analytics</h4>
          <p>
            Google Analytics is a web analytics service offered by Google that tracks and reports
            website traffic. Google uses the data collected to track and monitor the use of our
            Service. This data is shared with other Google services. Google may use the collected
            data to contextualise and personalise the ads of its own advertising network.
          </p>
          <p>
            You may opt-out of certain Google Analytics features through your mobile device
            settings, such as your device advertising settings or by following the instructions
            provided by Google in their Privacy Policy:{' '}
            <a
              href="https://policies.google.com/privacy"
              rel="external nofollow noreferrer"
              target="_blank"
            >
              https://policies.google.com/privacy
            </a>
          </p>
          <p>
            For more information on the privacy practices of Google, please visit the Google Privacy
            &amp; Terms web page:{' '}
            <a
              href="https://policies.google.com/privacy"
              rel="external nofollow noreferrer"
              target="_blank"
            >
              https://policies.google.com/privacy
            </a>
          </p>

          <h2>Links to Other Sites</h2>
          <p>
            Our Service may contain links to other sites that are not operated by us. If you click a
            third party link, you will be directed to that third party&apos;s site. We strongly
            advise you to review the Privacy Policy of every site you visit. We have no control over
            and assume no responsibility for the content, privacy policies or practices of any third
            party sites or services.
          </p>

          <h2>Children&apos;s Privacy</h2>
          <p>Our Service does not address anyone under the age of 18 (&#34;Children&#34;).</p>
          <p>
            We do not knowingly collect personally identifiable information from anyone under the
            age of 18. If you are a parent or guardian and you are aware that your Child has
            provided us with Personal Data, please contact us. If we become aware that we have
            collected Personal Data from children without verification of parental consent, we take
            steps to remove that information from our servers.
          </p>

          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by
            posting the new Privacy Policy on this page. We will let you know via email and/or a
            prominent notice on our Service, prior to the change becoming effective and update the
            &#34;effective date&#34; at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any changes. Changes to
            this Privacy Policy are effective when they are posted on this page.
          </p>

          <h2>Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us:</p>
          <ul>
            <li>
              <p>By email: sturevents@gmail.com</p>
            </li>
            <li>
              <p>
                By visiting this page on our website:{' '}
                <a href="http://sturevents.com/" rel="external noreferrer" target="_blank">
                  http://sturevents.com/
                </a>
              </p>
            </li>
          </ul>
        </div>
      </PageContent>
    </>
  );
};
